import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Reviews = () => (
  <Layout pageInfo={{ pageName: "recensies", pageTitle: "Recencies", bannerTitle: "website-header-01.png" }}>
    <Seo title="Recencies" />
    <Container>
      <section className="general-page" id="reviews">
        <Container>
          <Row>
            <Col xs={{ span: 12 }}>
              <div class="block">

                <h3>
                  Coach Yvonne is zeker aan te raden! - Susan
                </h3>

                Yvonne is professioneel en neemt de tijd om als ouder je verhaal te kunnen doen. Dankzij haar goede tips
                lukt het om rust te creëren tijdens het slaapritueel van onze Daan. Hij gaat eerder naar bed omdat hij
                na een lange dag ook aangeeft erg moe te zijn en dat heeft zijn vruchten afgeworpen. Daan voelt dat hij
                niet hoeft te vechten tegen zijn slaap en wordt daardoor uitgerust wakker. Het doel is bereikt. Coach
                Yvonne is zeker aan te raden!
              </div>
            </Col>

            <Col xs={{ span: 12 }}>
              <div className="block">

                <h3>
                  De informatie die Yvonne eruit kon halen was herkenbaar, maar gaf ook weer nieuwe inzichten. -
                  Floortje
                </h3>

                Wij waren positief verrast door de analyse van de huistekening van onze zoon (7 jaar). De informatie die
                Yvonne eruit kon halen was herkenbaar, maar gaf ook weer nieuwe inzichten in zijn belevingswereld.
                Verrassend op hoeveel manieren je kunt kijken naar zo’n simpele tekening en dat daar dan informatie
                uitkomt die echt slaat op jouw kind. De manier waarop Yvonne haar interpretaties beschrijft is heel
                respectvol en eerlijk. Ze geeft duidelijk weer wat ze observeert en wat dit zou kunnen betekenen, zonder
                aannames te doen. Al met al vinden wij het een heel leuk inkijkje in de diepere laag achter deze
                tekening.

              </div>
            </Col>

            <Col xs={{ span: 12 }}>
              <div class="block">

                <h3>
                  Als je met jezelf in de knoop zit of tegen dingen aanloopt, kan het zeker helpen om er op deze manier
                  naar te kijken! - Mara
                </h3>

                Ik wist niet goed of ik geloof in geboortepatronen, maar ik vond het heel leuk om te leren waar een aantal van mijn
                eigenschappen vandaan komen. Het was interessant en dat had ik vooraf niet verwacht. Als je met jezelf
                in de knoop zit of tegen dingen aanloopt, kan het zeker helpen om er op deze manier naar te kijken!
              </div>
            </Col>

            <Col xs={{ span: 12 }}>
              <div class="block">

                <h3>
                  Ik wist niet goed wat ik hiervan moest verwachten, maar wat was het leuk en vooral herkenbaar! - Kelly
                </h3>

                Met behulp van Yvonne is mijn geboorteverhaal in kaart gebracht. Ik wist niet goed wat ik hiervan moest
                verwachten, maar wat was het leuk en vooral herkenbaar! Ik was me er nooit zo van bewust wat een impact
                het verloop van een zwangerschap en geboorte kan hebben op een persoon. het was dus heel leerzaam om
                hier naar te kijken! Ik heb het geluk dat er geen echte bijzonderheden zijn geweest tijdens mijn
                geboorte, maar ik kan me voorstellen dat het een ander verhaal is wanneer dit wel het geval is en je een
                andere basis hebt. Ik heb ook veel gelachen omdat een hoop karaktereigenschappen zo herkenbaar waren en
                erg goed bij mij pasten! Yvonne begeleidde mij goed door de opdrachten heen en ik vond het heel leuk om
                op deze manier met haar aan de slag te gaan.
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Container>
  </Layout>
)

export default Reviews
